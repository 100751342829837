/**
* Project : simply-countdown
* File : simplyCountdown.theme.default
* Date : 27/06/2015
* Author : Vincent Loy <vincent.loy1@gmail.com>
*/
.simply-countdown {
  overflow: hidden;
  display: table;
  font-family: 'Arial', sans-serif;
}
.simply-countdown > .simply-section {
  width: 150px;
  height: 150px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  float: left;
  margin: 15px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.simply-countdown > .simply-section .simply-amount,
.simply-countdown > .simply-section .simply-word {
  display: block;
  text-align: center;
}
